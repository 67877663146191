import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';
import './style.css';

const speakersList = [
  {
    image: require('./images/starace.png'),
    title: "Francesco Starace",
    subtitle: "Chair Energy & Resource Efficiency al B20,<br />CEO & General Manager di Enel",
  },
  {
    image: require('./images/fracaro.png'),
    title: "Massimo Fracaro",
    subtitle: "Responsabile editoriale l’Economia del<br />Corriere della Sera"
  },
  {
    image: require('./images/gambarini.png'),
    title: "Francesca Gambarini",
    subtitle: "Giornalista l’Economia <br />del Corriere della Sera"
  },
  {
    image: require('./images/marchiaro.png'),
    title: "Mauro Marchiaro",
    subtitle: "Strategy & Consulting Lead Accenture Italy, Central Europe and Greece",
  },
  {
    image: require('./images/arcudi.png'),
    title: "Claudio Arcudi",
    subtitle: "Energy & Utilities Lead Accenture Italy, Central Europe and Greece",
  },
  {
    image: require('./images/bernacchi.png'),
    title: "Daniela Bernacchi",
    subtitle: "Segretario Generale Global Compact Network Italia (GCNI)"
  },
  {
    image: require('./images/careddu.png'),
    title: "Antonio Careddu",
    subtitle: "Presidente ANIMP ed EVP Saipem",
  },
  {
    image: require('./images/morselli.png'),
    title: "Lucia Morselli",
    subtitle: "Presidente del Consiglio di Amministrazione e Amministratore Delegato, ArcelorMittal",
  },
  {
    image: require('./images/testa.png'),
    title: "Federico Testa",
    subtitle: "Presidente Agenzia Nazionale per le Nuove Tecnologie, l’Energia e lo Sviluppo Economico Sostenibile ENEA",
  },
  {
    image: require('./images/dunlop.png'),
    title: "Kirsten Dunlop",
    subtitle: "Chief Executive Officer EIT Climate-KIC",
  },
]

const Speakers = props => (
  <Container fluid className="speakers-container">
    <Container>
      <h2 className="speakers-container-title">
        Relatori
      </h2>
      <Grid centered>
        {speakersList.map(speaker => (
          <Grid.Column computer={3} tablet={5} mobile={8} textAlign="center">
            <img src={speaker.image} style={{maxWidth: "100%"}} />
            <div className="speaker-title">
              {speaker.title}
            </div>
            <div className="speaker-subtitle" dangerouslySetInnerHTML={{__html: speaker.subtitle}}>
            </div>
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  </Container>
  )
  
export default Speakers;