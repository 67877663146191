import React from 'react';
import { Container } from 'semantic-ui-react';
import './style.css';
import accenture from './accenture.png';
import accenturehd from './accenture@2x.png';
import economia from './economia.png';
import economiahd from './economia@2x.png';
import cdsLogo from './cds-logo.png';
import cdsLogohd from './cds-logo@2x.png';

const Header = props => (
  <Container fluid className="header">
    <Container className="logo-container">
      <img src={economia} srcSet={`${economiahd} 2x`} alt="L'Economia" />
      <img src={accenture} srcSet={`${accenturehd} 2x`} className="left-logo" alt="Accenture" />
    </Container>
  </Container>
)

export default Header;