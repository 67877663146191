import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import './style.css';

const historyList = [
  {
    time: "11:00",
    description: [
      <b>APERTURA E SALUTI</b>, <br />,
      <b>Massimo Fracaro</b>, ", responsabile editoriale L’Economia del Corriere della Sera", <br />,
      <b>Mauro Marchiaro</b>, ", Strategy & Consulting Lead Accenture Italy, Central Europe and Greece",
    ]
  },
  {
    time: "11:10",
    description: [
      <b>KEYNOTE SPEECH</b>, <br />,
      <b>Francesco Starace</b>, ", Chair Energy & Resource Efficiency al B20, CEO & General Manager di Enel"
    ]
  },
  {
    time: "11:30",
    description: [
      <b>TALK</b>, <br />,
      <b>Massimo Fracaro</b>, ", responsabile editoriale L’Economia del Corriere della Sera", <br />,
      "dialoga con", <br />,
      <b>Claudio Arcudi</b>, ", Energy & Utilities Lead Accenture Italy, Central Europe and Greece"
    ]
  },
  {
    time: "11:50",
    description: [
      <b>CEO DISCUSSION</b>, <br />,
      <b>Daniela Bernacchi</b>, ", Segretario Generale Global Compact Network Italia (GCNI)", <br />,
      <b>Antonio Careddu</b>, ", Presidente ANIMP ed EVP Saipem", <br />,
      <b>Lucia Morselli</b>, ", Presidente del Consiglio di Amministrazione e Amministratore Delegato, ArcelorMittal", <br />,
      <b>Federico Testa</b>, ", Presidente Agenzia Nazionale per le Nuove Tecnologie, l'Energia e lo Sviluppo Economico Sostenibile ENEA", <br />,
      <br />,
      "Moderano", <br />,
      <b>Massimo Fracaro</b>, ", responsabile editoriale L’Economia del Corriere della Sera", <br />,
      <b>Francesca Gambarini</b>, ", giornalista L’Economia del Corriere della Sera",
    ]
  },
  {
    time: "12:40",
    description: [
      <b>INTERVENTO DI CHIUSURA</b>, <br />,
      <b>Kirsten Dunlop</b>, ", Chief Executive Officer EIT di Climate-KIC"
    ]
  },
]

const History = props => (
  <Container style={{ marginTop: 20 }}>
    <h2 className="history-title">Agenda</h2>

    <Grid>
      {historyList.map(historyItem => (
        <Grid.Row>
          <Grid.Column textAlign="center" computer={2} mobile={4}>
            <div className="time-box">{historyItem.time}</div>
          </Grid.Column>
          <Grid.Column className="description-box" computer={14} mobile={12}>
            {historyItem.description}
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  </Container>
)

export default History;