import React, { Component } from 'react';
import './style.css';
import io from 'socket.io-client';
import Aux from "../../Components/Aux"
import { Redirect } from 'react-router-dom'
import { Image, Container, Grid, Form, Segment, Input, Button, Comment } from 'semantic-ui-react'
import banner from '../header.svg'
import dateFormat from 'dateformat'
import 'whatwg-fetch'
import Speakers from '../Speakers';
import Header from '../Header';
import Footer from '../Footer';
import History from '../History';

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
      messageText: "",
    }

    this.initSocket = this.initSocket.bind(this)

    if (localStorage.getItem("jwtFinal")) {
      fetch("https://rcst4g-api.reportcongressi.com/old-messages").then(function(res) {
        return res.json()
      }).then(function(json) {
        this.setState({messages: json})
        this.initSocket()
      }.bind(this)).catch(function (err) {
        console.log(err)
        this.initSocket()
      }.bind(this))

    } else {
      this.state.redirectToLogin = true
    }
  }

  initSocket() {
    this.socket = io("https://rcst4g-api.reportcongressi.com", { query: "token="+localStorage.getItem("jwtFinal") });
    this.socket.on("message.stream", function (body) {
    }.bind(this));
  }

  componentDidMount() {
    if (this.chatbox) {
      this.chatbox.scrollTop = this.chatbox.scrollHeight
    }
  }

  componentDidUpdate() {
    if (this.chatbox) {
      this.chatbox.scrollTop = this.chatbox.scrollHeight
    }
  }

  sendMessage() {
    if (this.state.messageText.trim() === "") return;

    console.log(this.state.messageText);
    if (this.socket && this.socket.connected) {
      this.socket.emit("message.new", {message: this.state.messageText})
      this.setState({messageText: ""})
    }
  }

  render() {
    return (
      <Aux>
        {this.state.redirectToLogin && (
          <Redirect to="/login" />
        )}
        <Header />
        <Container fluid style={{marginTop: 20}} className="main-container">
          <Grid centered>
            <Grid.Column computer={12} tablet={16} mobile={16}>
              <Segment>
                <div className="ui embed">
                <iframe title="RCS" src="https://vimeo.com/event/804482/embed/7b2b36641a?autoplay=1" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
              </Segment>
            </Grid.Column>
            <style>
              {`
                @media(max-width: 990px) {
                  .hide-mobile {
                    display: none !important;
                  }
                }
              `}
            </style>
          </Grid>
        </Container>
        <Speakers />
        <History />
        <Footer />
      </Aux>
    );
  }
}