import React, { Component } from 'react'
import { Checkbox, Image, Button, Form, Grid, Message, Segment, Dimmer, Loader, Container } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import 'whatwg-fetch'
import Header from './Header'
import Speakers from './Speakers'
import History from './History'
import Footer from './Footer'
import talk4growth from './talk4growth.png'

export default class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      error: null,
      name: "",
      surname: "",
      email: "",
      company: "",
      redirect: (localStorage.getItem("jwtFinal") !== null)
    }
  }
  
  login () {
    this.setState({loading: true})

    var headers = new Headers()
    headers.append("Content-Type", "application/json")

    var bodyObject = {
      name: this.state.name,
      surname: this.state.surname,
      email: this.state.email,
      company: this.state.company
    }

    fetch('https://rcst4g-api.reportcongressi.com/register', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(bodyObject)
    }).then(function(res) {
      if (res.ok) {
        res.json().then(function (data) {
          localStorage.setItem("jwtFinal", data.jwtToken)
          this.setState({redirect: true, loading: false})
        }.bind(this)).catch(function(err) {
          console.log(err)
        })
      } else {
        res.json().then(function (data) {
          this.setState({error: data.reason, loading: false})
        }.bind(this)).catch(function(err) {
          console.log(err)
        })
      }
    }.bind(this)).catch(function(err) {
      this.setState({loading: false, error: "Errore inaspettato, ricarica la pagina per riprovare."})
    }.bind(this))
    /*setTimeout(function () {
      this.setState({
        loading: false,
        error: true
      })
    }.bind(this), 3000)*/
  }

  render () {
    return (
      <div className='login-form'>
      {this.state.redirect && (
        <Redirect to="/" />
      )}
      {/*
        Heads up! The styles below are necessary for the correct render of this example.
        You can do same with CSS, the main idea is that all the elements up to the `Grid`
        below must have a height of 100%.
      */}
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
      <Header />
      <Container>
        <Grid
          centered
          textAlign='center'
        >
          <Grid.Column computer={16} tablet={16} mobile={16} style={{ marginTop: "2rem" }}>
            <Segment className="login-container">
              <Grid>
                <Grid.Column computer={8} mobile={16} className="space-between">
                  <div>
                    <img src={talk4growth} />
                    <h1 className="login-header">
                      RE-ENERGIZING CHANGE
                    </h1>
                    <h2 className="login-subheader">
                      24 MARZO ORE 11.00
                    </h2>
                  </div>
                  <div className="login-header-note">
                  Compila i dati per seguire la diretta
                  </div>
                </Grid.Column>
                <Grid.Column computer={8} mobile={16} className="space-around">
                  <Form size='large' onSubmit={this.login.bind(this)}>
                    {this.state.loading && (
                      <Dimmer active>
                        <Loader />
                      </Dimmer>
                    )}
                    {this.state.error && (
                      <Message negative>
                        <p>{this.state.error}</p>
                      </Message>
                    )}
                    <Form.Input
                      fluid
                      placeholder="Nome"
                      icon="id badge"
                      iconPosition="left"
                      value={this.state.name}
                      onChange={e => this.setState({name: e.target.value})}
                    />
                    <Form.Input
                      fluid
                      placeholder="Cognome"
                      icon="id badge"
                      iconPosition="left"
                      value={this.state.surname}
                      onChange={e => this.setState({surname: e.target.value})}
                    />
                    <Form.Input
                      fluid
                      icon="mail outline"
                      iconPosition="left"
                      placeholder="Indirizzo E-Mail"
                      value={this.state.email}
                      onChange={e => this.setState({email: e.target.value})}
                    />
                    <Form.Input
                      fluid
                      icon="address book outline"
                      iconPosition="left"
                      placeholder="Azienda"
                      value={this.state.company}
                      onChange={e => this.setState({company: e.target.value})}
                    />
                    <Button className="login-button" color='blue' fluid size='large'>PARTECIPA</Button>
                  </Form>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
      <Speakers />
      <History />
      <Footer />
    </div>
    )
  }
}