import React from 'react';
import { Container } from 'semantic-ui-react';
import corriere from './logo-corriere.png';
import corrierehd from './logo-corriere@2x.png';
import './style.css';

const Footer = props => (
  <Container className="footer" textAlign="center">
    <hr className="separator" />
    <img src={corriere} srcSet={`${corrierehd} 2x`} />
    <div className="note">
    RCS MediaGroup S.p.A. - Divisione Quotidiani Sede legale: via Angelo Rizzoli, 8 - 20132 Milano | Capitale sociale: Euro 270.000.000,00<br />
    Codice Fiscale, Partita I.V.A. e Iscrizione al Registro delle Imprese di Milano n.12086540155 | R.E.A. di Milano: 1524326 | ISSN 2499-0485
    </div>
  </Container>
)

export default Footer;